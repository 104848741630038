import { Injectable, Inject } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MyserviceService } from './myservice.service';

@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {

  constructor(private http: MyserviceService,
    private router: Router) { }


  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  // canActivateChild(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let user = localStorage.getItem('userId');
    if (user.length) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
    //  return this.http.isLoggedIn.pipe(
    //    take(1),
    //    map((isLoggedIn: boolean) => {
    //      if (!isLoggedIn) {
    //        this.router.navigate(['/login']);
    //        return false;
    //      }
    //      return true;
    //    })
    //  );
  }
}
