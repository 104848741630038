import { Component, OnInit } from '@angular/core';
import { MyserviceService } from '../myservice.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  myServiceHere: any;
  HidePassword = false;
  hides = false;
  hide: boolean = true;
  userId: any;
  mockData: any;


  constructor(
    private myService: MyserviceService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
  ) {

  }
  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      pwd: ['', Validators.required]
    });
  }
  loginForm = new FormGroup({
    email: new FormControl(''),
    pwd: new FormControl(''),
  });


  onSubmit() {
    if (this.form.valid) {
      this.myService.login(this.form.value).subscribe((res: any) => {
        if (res.code == 1) {
          this.myService.openSnackBar(res.msg, 'OK')
        } else if (res.code == 0) {
          this.myService.openSnackBar(res.msg, 'OK')
        } else if (res.code == 2) {
          this.myService.openSnackBar(res.msg, 'OK')
        } else if (res.code == 3) {
          this.myService.openSnackBar(res.msg, 'OK')
        } else {
          this.myService.setLogin()
          localStorage.setItem('userId', (res.userId))
          localStorage.setItem('email', res.email)
          localStorage.setItem('role', res.role)
          localStorage.setItem('ComCode', res.ComCode)
          localStorage.setItem('name', res.name)
          localStorage.setItem('Domain', res.Domain)
          localStorage.setItem('authy_id', res.authy_id)
          localStorage.setItem('screen_access', JSON.stringify(res.screen_access) || JSON.stringify(this.mockData))
          // this.myService.getUserRole().subscribe((res:any)=>{  
          //   console.log("user role in -subject ",res)
          //   if(res==0){
          //     this.myService.setUserFlag()
          //   }else if(res==2){
          //     this.myService.setAdminFlag() 
          //   }
          // })
          // this.router.navigate(['/SecFactorAuth']);
          this.router.navigate(['/content/dashboard']);
          // this.router.navigateByUrl('/content/dashboard');
          // localStorage.setItem('comCode',"2009")          
          // this.router.navigate(['/dashboard']);
        }
      });
    } else {
      alert("Please enter required field")
    }
  }

  // hide(){
  //   this.HidePassword!=this.HidePassword
  // }

}
