import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import {
  MatInputModule, MatFormFieldModule, MatCardModule,
  MatIconModule, MatSnackBarModule, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { MyserviceService } from './myservice.service';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthGuard } from './auth.guard';
import { FilterPipe } from '../../filter.pipe';
import { HighlightDirective } from '../../highlight.directive';
import { SecFactorAuthComponent } from './sec-factor-auth/sec-factor-auth.component';
// import { VendorUpdateReqComponent } from './vendor-update-req/vendor-update-req.component';
import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SecFactorAuthComponent,
    FilterPipe,
    HighlightDirective,

  ],
  entryComponents: [],

  imports: [
    BrowserModule,
    AppRoutingModule, HttpClientModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatSnackBarModule,
    NgMaterialMultilevelMenuModule
  ],
  providers: [MultilevelMenuService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    MyserviceService, AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
