import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { LoginComponent } from './login/login.component';
import { SecFactorAuthComponent } from './sec-factor-auth/sec-factor-auth.component';

const routes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'SecFactorAuth',
    pathMatch: 'full', component: SecFactorAuthComponent
  },
  {
    path: 'content',
    loadChildren: './content/content.module#ContentModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  }

  //   {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  //   {path: 'article', component: ArticleComponent}, 
  //   {path : 'imdxPortal', cmponent : LoginComponent},
  //   {path: 'request', component:RequestComponent },
  //   {path: 'approval', component:ApprovalComponent },
  //   {path: 'dashboard', component:DashboardComponent },
  //   {path: 'vendor', component:VendorComponent },
  //   {path: 'vendorFetch', component:VendorFetchComponent },
  //   {path: 'approvedvendor', component:ApprovedvendorComponent},
  //   {path: 'createarticle', component:CreatearticleComponent},
  //   {path: 'createvendor', component:CreatevendorComponent},
  //   {path: 'settings', component:SettingsComponent},
  //   {path: 'createcustomer', component:CreatecustomerComponent},
  //   {path: 'creategl', component:GlComponent},
  //   {path: 'createsite', component:CreatesiteComponent},
  //   {path: 'usersetting', component:UsersettingComponent},
  //   {path: 'workflow', component:WorkflowComponent},
  //   {path: 'emailsetup', component:EmailsetupComponent},
  //   {path: 'chart', component:ChartComponent},
  //   {path: 'fetchgl', component:FetchglComponent},
  //   {path: 'fetchsite', component:FetchsiteComponent},
  //   {path: 'fetchcustomer', component:FetchcustomerComponent},
  //   {path: 'adminReg', component:AdminRegComponent},
  //   {path: 'chart', component:ChartComponent},
  //   {path: 'dmac-tranformation', component:DMACTransformComponent},
  //   {path: 'transformation', component:TransformationComponent},
  //   {path: 'LRtables', component:LRTablesComponent},
  //   {path: 'matrix', component:MatrixComponent},
  //   {path: 'articleSelect', component:ArticleSelectFieldComponent},
  //   {path: 'articleReq', component:ArticleRequestComponent},
  //   {path: 'oData', component:OdataComponent},
  //   {path: 'profitCentre', component:ProfitCentreComponent},
  //   {path: 'CostCentre', component:CostCentreComponent},
  //   {path: 'profitCentreList', component:ProfitCentreListComponent},
  //   {path: 'costCentreList', component:CostCentreListComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }