import { Component, OnInit } from '@angular/core';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MyserviceService } from '../myservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sec-factor-auth',
  templateUrl: './sec-factor-auth.component.html',
  styleUrls: ['./sec-factor-auth.component.scss']
})
export class SecFactorAuthComponent implements OnInit {

  form:FormGroup;
  FormGroup: FormGroup;
  authy_id: string;

  constructor(private _el: ElementRef, private fb: FormBuilder,
    private myService : MyserviceService,private router: Router) { }

    ngOnInit() {
      this.authy_id=localStorage.getItem("authy_id")
      console.log("authy",this.authy_id)
  
      this.FormGroup = new FormGroup({
        Digit1: new FormControl(''),
        Digit2: new FormControl(''),
        Digit3: new FormControl(''),
        Digit4: new FormControl(''),
        Digit5: new FormControl(''),
        Digit6: new FormControl(''),
        Digit7: new FormControl('')
      })
    }
  
  
    Submit(){
      
        let token=this.FormGroup.value.Digit1+this.FormGroup.value.Digit2+this.FormGroup.value.Digit3
        +this.FormGroup.value.Digit4+this.FormGroup.value.Digit5+this.FormGroup.value.Digit6
        +this.FormGroup.value.Digit7
        console.log("token",token);
  
        let data={
          authy_id:this.authy_id,
          Token:token
        }
        this.myService.VerifyOTP(data)
        .subscribe((res:any) => { 
        console.log("Reponse from otp",res)  
        if(res.success=="true"){
          this.myService.openSnackBar('Logged In', 'Done')
          this.router.navigate(['/content/dashboard']);
        }
        else{
          this.myService.openSnackBar('Invalid OTP', 'Done')
        }
  
      }, (error: any) => {
        this.myService.openSnackBar(error, 'Done')
  
        console.log(error) 
      });
    
    }
  
  
  
  
    @HostListener('keyup', ['$event']) onKeyDown(e: any) {
      if (e.srcElement.maxLength === e.srcElement.value.length) {
  
          e.preventDefault();
  
          let nextControl: any = e.srcElement.nextElementSibling;
         // Searching for next similar control to set it focus
          while (true)
          {
              if (nextControl)
              {
                  if (nextControl.type === e.srcElement.type)
                  {
                      nextControl.focus();
                      return;
                  }
                  else
                  {
                      nextControl = nextControl.nextElementSibling;
                  }
              }
              else
              {
                  return;
              }
          }
      }
  }
  
  
  }
  