import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExtLogicList } from './content/model/user';

@Injectable({
  providedIn: 'root'
})
export class MyserviceService {
  variencename: any;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,

  ) {
    this.currentUserSubject = new BehaviorSubject<string>(localStorage.getItem('userId'));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentRoleSubject = new BehaviorSubject<string>(localStorage.getItem('role'));
    this.currentRole = this.currentRoleSubject.asObservable();
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  private currentUserSubject: BehaviorSubject<string>;
  public currentUser: Observable<string>;

  private currentRoleSubject: BehaviorSubject<string>;
  public currentRole: Observable<string>;

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // baseUrl: string = 'http://192.168.1.6:8006/';
  // baseUrl: string = 'http://34.93.247.28:80/';
  baseUrl = 'https://www.saphelpline.com/';
  // baseUrl = 'https://imdx.dev/';



  //  baseUrl: string =   "http://localhost:8006/";
  testUrl = 'https://mdg_backend_app-shiny-fox-vx.cfapps.us10.hana.ondemand.com/';
  getUserId() {
    // console.log("In service class current user",this.currentUser)
    return this.currentUser;
  }
  getUserRole() {
    // console.log("In service class current user",this.currentRole)
    return this.currentRole;
    // return this.currentRoleSubject.asObservable();
  }
  setLogin() {
    this.loggedIn.next(true);
  }
  OnclearAll() {
    console.log('Call this logout');
    localStorage.clear();
    this.loggedIn.next(false);
    // this.currentRoleSubject.unsubscribe();
    // this.currentUserSubject.unsubscribe();
    // this.userRole=null;
    // this.userId=null;
  }


  login(data: any) {

    console.log('loggd in');
    return this.http.post(this.baseUrl + 'login', data);

  }

  // logout() {
  // this.loggedIn.next(false);
  // this.router.navigate(['/login']);
  // }

  raise(data: any) {
    return this.http.post(this.baseUrl + 'CreateRequest', data);
  }

  vendor(data: any) {
    return this.http.post(this.baseUrl + 'CreateVendorRequest', data);
  }

  dataFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchArticlerequest', data);
  }

  vendorFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchVendorrequest', data);
  }
  articleapproved(data: any) {
    return this.http.post(this.baseUrl + 'RequestForAprove', data);
  }
  approveArticleHierarchyRequest(data: any) {
    return this.http.post(this.baseUrl + 'ApproveArticleHierarchyRequest', data);
  }
  approveddata(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedArticleRequest', data);
  }
  fetchApprovedAHRequest(data: any) {
    return this.http.post(this.baseUrl + 'fetchApprovedAHRequest', data);
  }
  vendorapproved(data: any) {
    return this.http.get(this.baseUrl + 'fetchVendorrequest', data);
  }
  approvedvendorList(data: any) {
    return this.http.get(this.baseUrl + 'fetchVendorrequest', data);
  }
  articleapprovalafter(data: any) {
    return this.http.post(this.baseUrl + 'CreateVendor', data);
  }
  createarticledata(data: any) {
    return this.http.get(this.baseUrl + 'FetchCreateConfirmdata', data);
  }
  totalrequest(data: any) {
    return this.http.post(this.baseUrl + 'fetchRequest', data);
  }
  approverequest(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedRequest', data);
  }
  approveixosrequest(data: any) {
    return this.http.post(this.baseUrl + 'changeSalesOrderStatusInStagingTable', data);
  }
  approvedvendor(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedVendorRequest', data);
  }
  articlecreate(data: any) {
    return this.http.post(this.baseUrl + 'CreateArticleRequest', data);
  }
  customerFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchCustomerrequest', data);
  }
  siteFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchSiterequest', data);
  }
  glFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchGLrequest', data);
  }
  denyFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedRequest', data);
  }
  fetchDeniedAHRequest(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedAHRequest', data);
  }
  articledeny(data: any) {
    return this.http.post(this.baseUrl + 'DennyRequest', data);
  }
  DeniedArticleHierarchyRequest(data: any) {
    return this.http.post(this.baseUrl + 'DeniedArticleHierarchyRequest', data);
  }
  denyvendordata(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedVendorRequest', data);
  }
  workflow(data: any) {
    return this.http.post(this.baseUrl + 'CreateWorkflow', data);
  }
  userprofile(data: any) {
    return this.http.post(this.baseUrl + 'userProfileSetup', data);
  }
  fetchworkflowlist() {
    return this.http.get(this.baseUrl + 'fetchWorkflowDetails');
  }
  Glcreate(data: any) {
    console.log('res from server', data);
    return this.http.post(this.baseUrl + 'CreateGLRequest', data);
  }
  createsite(data: any) {
    // console.log("service file response ",this.baseUrl + 'CreateSiteRequest',data)
    return this.http.post(this.baseUrl + 'CreateSiteRequest', data);
  }
  createcustomer(data: any) {
    return this.http.post(this.baseUrl + 'CreateCustomerRequest', data);
  }
  glapproved(data: any) {
    return this.http.post(this.baseUrl + 'RequestForAprove', data);
  }
  gldeny(data: any) {
    return this.http.post(this.baseUrl + 'DennyRequest', data);
  }
  approvedgldata(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedGLRequest', data);
  }
  denyFetchgl(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedGLRequest', data);
  }
  siteapproved(data: any) {
    return this.http.post(this.baseUrl + 'RequestForAprove', data);
  }
  sitedeny(data: any) {
    return this.http.post(this.baseUrl + 'DennyRequest', data);
  }
  isoxstagingdeny(data: any) {
    return this.http.post(this.baseUrl + 'changeSalesOrderStatusInStagingTable', data);
  }
  approvedsitedata(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedSiteRequest', data);
  }
  denyFetchsite(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedSiteRequest', data);
  }
  customerapproved(data: any) {
    return this.http.post(this.baseUrl + 'RequestForAprove', data);
  }
  customerdeny(data: any) {
    return this.http.post(this.baseUrl + 'DennyRequest', data);
  }
  approvedcustomerdata(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedCustomerRequest', data);
  }
  denyFetchcustomer(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedCustomerRequest', data);
  }
  usersettingFetch(data: any) {
    return this.http.post(this.baseUrl + 'fetchUserprofiledata', data);
  }
  updateWorkFlowAPI(data: any) {
    return this.http.post(this.baseUrl + 'updateWorkflowData', data);
  }
  deletedata(data: any) {
    return this.http.post(this.baseUrl + 'DeleteRequest', data);
  }
  deleteArticleHierarchyRequest(data: any) {
    return this.http.post(this.baseUrl + 'DeleteArticleHierarchyRequest', data);
  }

  adminReg(data: any) {
    return this.http.post(this.baseUrl + 'RegisterCompanyOrAdmin', data);
  }

  VendorTableStruct(data: any) {
    return this.http.post(this.baseUrl + 'VendorTableStruct', data);
  }

  ArticleTableStruct(data: any) {
    return this.http.post(this.baseUrl + 'ArticleTableStruct', data);
  }

  CustomerTableStruct(data: any) {
    return this.http.post(this.baseUrl + 'CustomerTableStruct', data);
  }

  ArticleExcelUpload(ExcelSheetdata: any) {
    console.log('hello in service file', ExcelSheetdata);
    return this.http.post(this.baseUrl + 'uploadArticleMaster', ExcelSheetdata);
  }

  openmateiralInfoDialog(formData: any) {
    return this.http.post(this.baseUrl + 'preValidationCheckMaterial', formData);
  }

  openInfoDialog(formData: any) {
    return this.http.post(this.baseUrl + 'fetchArticleDetails', formData);
  }
  openisoxInfoDialog(formData: any) {
    return this.http.post(this.baseUrl + 'fetchSalesOrder', formData);
  }
  showMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'materialslist', formData);
  }
  showCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'customerlist', formData);
  }
  showVendor(formData: any) {
    return this.http.post(this.baseUrl + 'vendorlist', formData);
  }
  searchVendorData(formData: any) {
    return this.http.post(this.baseUrl + 'vendorlistByPartnerGrouping', formData);
  }
  searchData(formData: any) {
    return this.http.post(this.baseUrl + 'materialslistBySubString', formData);
  }
  searchDdata(formData: any) {
    return this.http.post(this.baseUrl + 'materialslistByProductType', formData);
  }
  searchCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'customerlistByAccountgroup', formData);
  }
  getStorage(formData: any) {
    return this.http.post(this.baseUrl + 'StorLocationSet', formData);
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'bottom',
      // panelClass: ["font-family:'Open Sans', sans-serif;"]
    });
  }

  SelectArticleField(formData: any) {
    return this.http.post(this.baseUrl + 'SelectFeildAndCatOfArticle', formData);
  }

  fetchSelectedArticleFields(formData: any) {
    return this.http.post(this.baseUrl + 'fetchArticleCatAndField', formData);
  }

  fetchFields() {
    return this.http.get(this.baseUrl + 'getalldataofArticleCatAndField');
  }

  DeleteWorkflow(formData: any) {
    return this.http.post(this.baseUrl + 'DeletUserWorkflow', formData);
  }

  DeleteUserProfile(formData: any) {
    return this.http.post(this.baseUrl + 'Deleteuserprofile', formData);
  }

  fetchAllRequests(formData: any) {
    return this.http.post(this.baseUrl + 'FetchAllRequestDefrrnciatewithDomain', formData);
  }
  fetchAllIsoxReq(formData: any) {
    return this.http.post(this.baseUrl + 'fetchAllrequest', formData);
  }
  fetchAllIsoxStagingReq(formData: any) {
    return this.http.post(this.baseUrl + 'listAllSalesOrder', formData);
  }
  FetchLastSevenDayData(formData: any) {
    return this.http.post(this.baseUrl + 'FetchLastSevenDayData', formData);
  }

  fetchAllReq(formData: any) {
    return this.http.post(this.baseUrl + 'fetchAllrequest', formData);
  }
  fetchArticleHierarchyRequest(formData: any) {
    return this.http.post(this.baseUrl + 'fetchArticleHierarchyRequest', formData);
  }

  SendDataSap(formData: any) {
    return this.http.post(this.baseUrl + 'Senddatatosap', formData);
  }
  SendToSAPArticleHierarchyRequest(formData: any) {
    return this.http.post(this.baseUrl + 'SendToSAPArticleHierarchyRequest', formData);
  }
  orderType(formData: any) {
    return this.http.post(this.baseUrl + 'SalesOrderTypeSet', formData);
  }
  salesGroup(formData: any) {
    return this.http.post(this.baseUrl + 'SalesGroupSet', formData);
  }
  salesOffice(formData: any) {
    return this.http.post(this.baseUrl + 'SalesOfficeSet', formData);
  }
  saveVarience(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceArticle', formData);
  }
  saveVarienceOfCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceCustomer', formData);
  }
  saveVarienceOfVendor(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceVendor', formData);
  }
  saveVarienceOfMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceMaterial', formData);
  }
  saveVarienceOfBom(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceBom', formData);
  }
  saveVarienceOfRouting(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceRouting', formData);
  }
  fetchVarience(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceArticle', formData);
  }
  fetchVarienceOfCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceCustomer', formData);
  }
  fetchVarienceOfMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceMaterial', formData);
  }
  fetchMRPType() {
    return this.http.post(this.baseUrl + 'MrpTypeViewSet ', {});
  }
  fetchQType() {
    return this.http.post(this.baseUrl + 'QMControlKeySet', {});
  }
  fetchProcurementType() {
    return this.http.post(this.baseUrl + 'procurementTypeSet', {});
  }
  fetchFiscalYearList() {
    return this.http.post(this.baseUrl + 'FiscalYearVarSet ', {});
  }
  fetchQMControlKeyList() {
    return this.http.post(this.baseUrl + 'FiscalYearVarSet ', {});
  }
  fetchInspectionSetUpList() {
    return this.http.post(this.baseUrl + 'FiscalYearVarSet ', {});
  }
  fetchVarienceOfRouting(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceRouting', formData);
  }
  fetchVarienceOfBom(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceBom', formData);
  }
  fetchVarienceOfVendor(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceVendor', formData);
  }
  deleteVarienceOfMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'DeleteMaterialVariance', formData);
  }
  deleteVarienceOfCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'DeleteCustomerVariance', formData);
  }
  deleteVarienceOfVendor(formData: any) {
    return this.http.post(this.baseUrl + 'DeleteVendorVariance', formData);
  }
  deleteVarienceOfBom(formData: any) {
    return this.http.post(this.baseUrl + 'deleteVarianceBom', formData);
  }
  deleteVarienceOfRouting(formData: any) {
    return this.http.post(this.baseUrl + 'deleteVarianceRouting', formData);
  }
  updateVarienceOfMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'updateVarianceMaterial', formData);
  }
  validtateArticleList(formData: any) {
    return this.http.post(this.baseUrl + 'validtateArticleList', formData);
  }
  updateVarienceOfCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'DeleteCustomerVariance', formData);
  }
  updateVarienceOfVendor(formData: any) {
    return this.http.post(this.baseUrl + 'DeleteVendorVariance', formData);
  }

  usersList(formData: any) {
    return this.http.post(this.baseUrl + 'FetchAllUsersofComCode', formData);
  }

  fetchArticleInfo(formData: any) {
    return this.http.post(this.baseUrl + 'fetchRequestDetails', formData);
  }


  UpdateArticleInfo(formData: any) {
    return this.http.post(this.baseUrl + 'UpdateArticleRequest', formData);
  }

  SiteExcelUpload(ExcelSheetdata: any) {
    console.log('hello in service file', ExcelSheetdata);
    return this.http.post(this.baseUrl + '/uploadSiteMaster', ExcelSheetdata);
  }

  UpdateSiteInfo(formData: any) {
    return this.http.post(this.baseUrl + 'UpdateSiteRequest', formData);
  }

  downloadReq(formData: any) {
    return this.http.post(this.baseUrl + 'ConvertjsondataIntoExcel', formData);
  }

  SendReqBot(formData: any) {
    return this.http.post(this.baseUrl + 'SiteApprovedDataAttachement', formData);
  }

  UpdateCustomerInfo(formData: any) {
    return this.http.post(this.baseUrl + 'UpdateCustomerRequest', formData);
  }

  UpdateVendorInfo(formData: any) {
    return this.http.post(this.baseUrl + 'UpdateVendorRequest', formData);
  }
  MassUploadinisoxStaging(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadisoxStaging', formData);
  }
  UpdateGLInfo(formData: any) {
    return this.http.post(this.baseUrl + 'UpdateGLRequest', formData);
  }

  validateArticleNumber(formData: any) {
    return this.http.post(this.baseUrl + 'hierarchyNodeValidation', formData);
  }
  ArticleHierarchy(formData: any) {
    return this.http.post(this.baseUrl + 'articleHerarchyMultiple', formData);
  }
  updateArticleHierarchy(newDate: any) {
    return this.http.post(this.baseUrl + 'updateArticleHierarchyData', newDate);
  }
  createArticleHierarchy(newDate: any) {
    return this.http.post(this.baseUrl + 'CreateArticleHierarchyRequest', newDate);
  }
  SalesOrg(formData: any) {
    return this.http.post(this.baseUrl + 'salesOrgSet', formData);
  }
  rangeArticleList(formData: any) {
    return this.http.post(this.baseUrl + 'articleSetByPrepackRange', formData);
  }
  equalDate(formData: any) {
    return this.http.post(this.baseUrl + 'articleSetByDateEqual', formData);
  }
  betweenDate(formData: any) {
    return this.http.post(this.baseUrl + 'articleSetByDateRange', formData);
  }
  DateAndArticle(formData: any) {
    return this.http.post(this.baseUrl + 'articleSetByArtInDateRange', formData);
  }
  BeforeDate(formData: any) {
    return this.http.post(this.baseUrl + 'articleSetByDateLess', formData);
  }
  AfterDate(formData: any) {
    return this.http.post(this.baseUrl + 'articleSetByDateGreater', formData);
  }


  downloadSiteTemplate(formData: any) {
    return this.http.post(this.baseUrl + 'DownloadSiteDataByUser', formData);
  }
  downloadArticleTemplate(formData: any) {
    return this.http.post(this.baseUrl + 'DownloadArticleRequest', formData);
  }

  approveReq(formData: any) {
    return this.http.post(this.baseUrl + 'RequestForAprove', formData);
  }

  CreateCostCentre(data: any) {
    return this.http.post(this.baseUrl + 'CreateCostProfileRequest', data);
  }

  CreateProfitCentre(data: any) {
    return this.http.post(this.baseUrl + 'CreateProfitProfileRequest', data);
  }


  SendVendorDatatoSAP(data: any) {
    return this.http.post(this.baseUrl + 'SendVendorToSAPimdx', data);
  }
  SendVendormassUploadDatatoSAP(data: any) {
    return this.http.post(this.baseUrl + 'massUploadVendorToSap', data);
  }
  VerifyOTP(data: any) {
    return this.http.post(this.baseUrl + 'VeryfiyToke', data);
  }

  CreateBill(data: any) {
    return this.http.post(this.baseUrl + 'CreateBOMRequest', data);
  }

  ShowBill(data: any) {
    return this.http.post(this.baseUrl + 'fetchallBOM', data);
  }
  BOM_Create_Add_item(data: any) {
    return this.http.post(this.baseUrl + 'CreateBomReq', data);
  }

  Bom_display_Item(data: any) {
    return this.http.post(this.baseUrl + 'fetchComponentBom', data);
  }
  Bom_display_Item_updated(data: any) {
    return this.http.post(this.baseUrl + 'fetchComponentBom', data);
  }
  BOMLine(data: any) {
    return this.http.post(this.baseUrl + 'updateBomLine', data);
  }

  updateSubItem(data: any) {
    return this.http.post(this.baseUrl + 'updateSubItem', data);
  }

  fetchSubItemForComponent(data: any) {
    return this.http.post(this.baseUrl + 'fetchSubItemForComponent', data);
  }


  BOMFinalRequest(data: any) {
    return this.http.post(this.baseUrl + 'CreateBOMRequestDumToOrig', data);
  }

  SendDataSAP(formdata: any) {
    return this.http.post(this.baseUrl + 'SendBOMToSAP', formdata);
  }
  SendMassDataSAP(formdata: any) {
    return this.http.post(this.baseUrl + 'massUploadBomReqToSap', formdata);
  }

  VerifyMaterialData(data: any) {
    return this.http.post(this.baseUrl + 'GetMAtList', data);
  }

  FetchApprovedBOMReq(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedBOMRequest', data);
  }

  FetchDenyBOMreq(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedBOMRequest', data);
  }


  FetchApprovedRoutingReq(data: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedRoutingRequest', data);
  }

  FetchDenyRoutingreq(data: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedRoutingRequest', data);
  }
  MaterialData(data: any) {
    return this.http.post(this.baseUrl + 'GetHelpForMaterial', data);
  }

  CreateRouting(formData: any) {
    return this.http.post(this.baseUrl + 'CreateRouting', formData);
  }

  UploadBOMExcel(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadBomReq', formData);
  }
  filllExtendedData(formData: any) {
    return this.http.post(this.baseUrl + 'materialExtensionData', formData);

  }
  PlantValidation(formData: any) {
    return this.http.post(this.baseUrl + 'GetMatAndPlantValid', formData);
  }
  PlantByMaterialValidation(formData: any) {
    return this.http.post(this.baseUrl + 'MatPantValiInMaterial', formData);
  }
  getBOMVarinetUniquID(formData: any) {
    return this.http.post(this.baseUrl + 'BOMVariant', formData);
  }

  MaterialCreation(formData: any) {
    return this.http.post(this.baseUrl + 'CreateMaterialRequest', formData);
  }
  createExtendedMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'materialExtensionInSAP', formData);
  }

  ArticleCreation(formData: any) {
    return this.http.post(this.baseUrl + 'CreateArticleRequest', formData);
  }
  ExtendPlant(formData: any) {
    return this.http.post(this.baseUrl + 'AddPlantInMaterial', formData);
  }

  ExtendSales(formData: any) {
    return this.http.post(this.baseUrl + 'AddSaleDataInMaterial', formData);
  }

  MaterialApprovedReq(formData: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedMaterialRequest', formData);
  }

  isoxApprovedReq(formData: any) {
    return this.http.post(this.baseUrl + 'fetchAprovedSalesRequest', formData);
  }
  isoxstagingApprovedReq(formData: any) {
    return this.http.post(this.baseUrl + 'listAllApprovedSalesOrder', formData);
  }


  fetchMassUploadMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'massMessageReadMaterial', formData);
  }


  fetchMassUploadVendor(formData: any) {
    return this.http.post(this.baseUrl + 'massMessageReadVendor', formData);
  }

  fetchMassUploadCustomer(formData: any) {
    return this.http.post(this.baseUrl + 'massMessageReadVendor', formData);
  }
  fetchMassUploadBOM(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadBomReqRead', formData);
  }

  fetchMassUploadRouting(formData: any) {
    return this.http.post(this.baseUrl + 'massMessageReadRouting', formData);
  }

  MaterialDeniedReq(formData: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedMaterialRequest', formData);
  }
  isoxDeniedReq(formData: any) {
    return this.http.post(this.baseUrl + 'fetchDeniedSalesRequest', formData);
  }
  isoxstagingDeniedReq(formData: any) {
    return this.http.post(this.baseUrl + 'listAllRejectSalesOrder', formData);
  }
  salesReason(formData: any) {
    return this.http.post(this.baseUrl + 'SalesRejectReasons', formData)
  }
  salesCategory(formData: any) {
    return this.http.post(this.baseUrl + 'SalesItemCategorySet', formData)
  }
  materialNumber(formData: any) {
    return this.http.post(this.baseUrl + 'SalesMaterialSet', formData)
  }
  SendCustomerReqSAP(formData: any) {
    return this.http.post(this.baseUrl + 'SendCustomerToSAP', formData);
  }
  massUploadCustomerToSap(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadCustomerToSap', formData);
  }
  MaterialValidation(formData: any) {
    return this.http.post(this.baseUrl + 'Materialvalidation', formData);
  }

  MatandPlantValidation(formData: any) {
    return this.http.post(this.baseUrl + 'MatPantValiInMaterial', formData);
  }

  SendRouteToSap(formData: any) {
    return this.http.post(this.baseUrl + 'SendRouteToSap', formData);
  }
  sendMassRouteToSap(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadRoutingToSap', formData);
  }
  SendMaterialtoSAP(formData: any) {
    return this.http.post(this.baseUrl + 'SendMaterialtosap', formData);
  }
  SendisoxtoSAP(formData: any) {
    return this.http.post(this.baseUrl + 'sendSalesOrderToSAP', formData);
  }
  SendstageingisoxtoSAP(formData: any) {
    return this.http.post(this.baseUrl + 'changeSalesOrderStatusInStagingTable', formData);
  }
  SendUploadMaterialtoSAP(formData: any) {
    return this.http.post(this.baseUrl + 'SendMassMaterialRequest', formData);
  }

  MassUploadVendorCreate(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadVendorCreate', formData);
  }
  MassUploadinMaterial(formData: any) {
    return this.http.post(this.baseUrl + 'massUploadMaterial', formData);
  }
  MassUploadinArticle(formData: any) {
    return this.http.post(this.baseUrl + '', formData);
  }
  MaterialGroupList(formData: any) {
    return this.http.post(this.baseUrl + 'MatGroupHelp', formData);
  }
  saveLogic(formData: any) {
    return this.http.post(this.baseUrl + 'saveLogic', formData);
  }
  availabilityCheck(formData: any) {
    return this.http.post(this.baseUrl + 'AvailCheckSet', formData);
  }
  validateMaterialIndustrySectorSet(formData: any) {
    return this.http.post(this.baseUrl + 'validateMaterialIndustrySectorSet', formData);
  }
  getNextSuffix() {
    return this.http.get(this.baseUrl + 'getNextSuffix');
  }
  timeUnitList(formData: any) {
    return this.http.post(this.baseUrl + 'TimeUnitSet', formData);
  }
  valuationClassList(formData: any) {
    return this.http.post(this.baseUrl + 'ValClassSet', formData);
  }
  weightUnitList(formData: any) {
    return this.http.post(this.baseUrl + 'WeightUnitSet', formData);
  }
  plantStorageList(formData: any) {
    return this.http.post(this.baseUrl + 'PlantHelpSet', formData);
  }
  purchasingKeyValueList(formData: any) {
    return this.http.post(this.baseUrl + 'PurchValKeySet', formData);
  }

  MaterialUnitofMeasure(formData: any) {
    return this.http.post(this.baseUrl + 'MatEAHelp', formData);
  }

  MaterialTypeList(formData: any) {
    return this.http.post(this.baseUrl + 'MatTypeHelp', formData);
  }


  DownloadMaterialRequest(formData: any) {
    return this.http.post(this.baseUrl + 'DownloadMaterialMaster', formData);
  }

  CreateVendor(formData: any) {
    return this.http.post(this.baseUrl + 'CreateVendorRequest', formData);
  }

  CompanyCodebySAP(data: any) {
    return this.http.post(this.baseUrl + 'CompanycodeVlidationInVendor', data);
  }

  TaxIDbySAP(data: any) {
    return this.http.post(this.baseUrl + 'TextTypeSetVlidationInVendor', data);
  }

  CurrencybySAP(data: any) {
    return this.http.post(this.baseUrl + 'CurrencyVlidationInVendor', data);
  }

  CountrybySAP(data: any) {
    return this.http.post(this.baseUrl + 'CountryVlidationInVendor', data);
  }

  TaxNumberVerify(data: any) {
    return this.http.post(this.baseUrl + 'TaxNumberSetVlidationInVendor', data);
  }

  MassUploadinCustomer(data: any) {
    return this.http.post(this.baseUrl + 'massUploadCustomer', data);
  }
  MassUploadrouting(data: any) {
    return this.http.post(this.baseUrl + 'massUploadRoutingCreate', data);
  }


  PurchasingGrp(data: any) {
    return this.http.post(this.baseUrl + 'PurchaseGroupVlidationInVendor', data);
  }

  DistChannel(data: any) {
    return this.http.post(this.baseUrl + 'DistributionChannelHelp', data);
  }

  CustomerGrp(data: any) {
    return this.http.post(this.baseUrl + 'CustomerGroupHelp', data);
  }

  SalesOrgCustomer(data: any) {
    return this.http.post(this.baseUrl + 'SalesOrgHelp', data);
  }
  DivisionCustomer(data: any) {
    return this.http.post(this.baseUrl + 'DivisionHelp', data);
  }

  CurrencyCustomerr(data: any) {
    return this.http.post(this.baseUrl + 'CurrencyHelp', data);
  }

  CustomerAccountGroup(data: any) {
    return this.http.post(this.baseUrl + 'CustomerAccountGroupHelp', data);
  }

  fetchCountryList(data: any) {
    return this.http.post(this.baseUrl + 'countrySet', data);
  }
  fetchRegionList(data: any) {
    return this.http.post(this.baseUrl + 'countryRegionCode', data);
  }

  CustomerReconcilationAcct(data: any) {
    return this.http.post(this.baseUrl + 'ReconciliationAccountHelp', data);
  }

  AcctGroupVendor(data: any) {
    return this.http.post(this.baseUrl + 'AccountGroupVlidationInVendor', data);
  }

  VendorReconcilationAcct(data: any) {
    return this.http.post(this.baseUrl + 'ReconciliationAccountVlidationInVendor', data);
  }

  PurchaseGrpVlidInVendorFunc(data: any) {
    return this.http.post(this.baseUrl + 'PurchaseGrpVlidInVendor', data);
  }

  purchaseGroupSetForCustomer(data: any) {
    return this.http.post(this.baseUrl + 'PurchasingGrpSet', data);
  }

  dunningprocCustomer(data: any) {
    return this.http.post(this.baseUrl + 'dunningproc', data);
  }

  PaymentTermsSetinCustomer(data: any) {
    return this.http.post(this.baseUrl + 'PaymentTermsSet', data);
  }

  soldToParty(data: any) {
    return this.http.post(this.baseUrl + 'CustomerSet', data);
  }
  pricelist(data: any) {
    return this.http.post(this.baseUrl + 'PriceListTypeSet', data);
  }
  PartnerFunctionsSetinCustomer(data: any) {
    return this.http.post(this.baseUrl + 'PartnerFunctionsSet', data);
  }

  partnerNumberAndDescription() {
    return this.http.post(this.baseUrl + 'CustomerSet', {});
  }

  TransportZoneSetinCustomer(data: any) {
    return this.http.post(this.baseUrl + 'TransportZoneSet', data);
  }

  PriceGroupSetCustomer(data: any) {
    return this.http.post(this.baseUrl + 'PriceGroupSet', data);
  }

  CusPricingProcSetCustomer(data: any) {
    return this.http.post(this.baseUrl + 'CusPricingProcSet', data);
  }

  ShipconditionsSetinCustomer(data: any) {
    return this.http.post(this.baseUrl + 'ShipconditionsSet', data);
  }

  IncoTermsSetinCustomer(data: any) {
    return this.http.post(this.baseUrl + 'IncoTermsSet', data);
  }

  paymentTerm(data: any) {
    return this.http.post(this.baseUrl + 'PaymentTermsSet', data);
  }
  BOMUsageList(data: any) {
    return this.http.post(this.baseUrl + 'BOMUsageSet', data);
  }
  ICTList(data: any) {
    return this.http.post(this.baseUrl + 'BOMItemCategorySet', data);
  }
  UOMList(data: any) {
    return this.http.post(this.baseUrl + 'BOMUoMSet', data);
  }
  partnerFunctionData_Customer(data: any) {
    return this.http.post(this.baseUrl + 'PartnerFunctionsSet', data);
  }
  taxClassificationInfo_Customer(data: any) {
    return this.http.post(this.baseUrl + 'TaxCharClassSet', data);
  }
  getTLStatusSetList(data: any) {
    return this.http.post(this.baseUrl + 'TLStatusSet', data);
  }
  getTLUsageSetList(data: any) {
    return this.http.post(this.baseUrl + 'TLUsageSet', data);
  }
  getTLControlKeySetList(data: any) {
    return this.http.post(this.baseUrl + 'TLControlKeySet', data);
  }
  getTimeUnitSetList(data: any) {
    return this.http.post(this.baseUrl + 'UoM_TimeSet', data);
  }
  getBOMInfo(data: any) {
    return this.http.post(this.baseUrl + 'fetchRequestBom', data);
  }
  downloadFile(path: any) {
    return this.http.get(this.baseUrl + `${path}`, { responseType: 'blob' });
  }
  getLogicList() {
    return this.http.get(this.baseUrl + 'getLogicList');
  }
  updateUser(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'updateLogicByID', data);
  }
  /// Added by Parul
  updateAccess(formData: any) {
    return this.http.post(this.baseUrl + 'updateModuleAccess', formData);
  }


  isoxcreate(formData: any) {
    return this.http.post(this.baseUrl + 'salesOrderCreate', formData);
  }
  isoxstagingcreate(formData: any) {
    return this.http.post(this.baseUrl + 'createSalesOrderInStagingTable', formData);
  }
  fetchisoxData(formData: any) {
    return this.http.post(this.testUrl + 'fetchSalesOrderBTP', formData);
  }
  SendisoxToSap(formData: any) {
    return this.http.post(this.testUrl + 'SalesOrderToSap', formData);
  }

  //ISPX portal api end points

  VendorRegistration(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'RequestToVendor', data);
  }

  VendorRequest(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'CreateRequestByVendor', data);
  }
  //Profit center APIs
  controlAreaHelp(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'controlAreaHelp', data);
  }
  userResponsibleHelp(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'userResponsibleHelp', data);
  }
  profitSegmentHelp(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'profitSegmentHelp', data);
  }
  CreateprofitRequest(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'CreateprofitRequest', data);
  }
  fetchprofitcenterRequest(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + '/fetchprofitcenterRequest', data);
  }
  profitcentergroupHelp(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'profitcentergroupHelp', data);
  }
  POSOutboundProfile(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'POSOutboundProfile', data);
  }

  profitcentwrgroupHelp(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'profitcentwrgroupHelp', data);
  }
  SendToSAPprofitcenterRequest(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'SendToSAPprofitcenterRequest', data);
  }
  UploadProfitCenterUpload(ExcelSheetdata: any) {
    // console.log("return from service file", data) edit by abhinav
    // return this.http.get(this.baseUrl + `${path}`, { responseType: 'blob' });
    return this.http.post(this.baseUrl + 'massUploadProfitcenter', ExcelSheetdata);

  }
  downloadProfitCenterTemplate(path: any) {
    return this.http.get(this.baseUrl + `${path}`, { responseType: 'blob' });

  }
  SendToSAPprofitcenterMassRequest(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'SendMassprofitcenterRequest', data);
  }

  ////End Profit center
  TotalRequests(data: any) {
    return this.http.post(this.baseUrl + 'fetchallrequestAccToUser', data);
  }

  MonthlyRequest(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'fetchrequestWithinMonth', data);
  }


  ProgressBar(data: any) {
    console.log(" from service file", data)
    return this.http.post(this.baseUrl + 'ProgessbarDetail', data);
  }

  CompleteRequestList(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'fetchallcompletedReq', data);
  }

  OpenRequestList(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'fetchallOpenReq', data);
  }

  pieChartAPI(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'FetchStatusdataNum', data);
  }

  UploadFileData(data: any) {
    console.log("return from service file", data)
    return this.http.post(this.baseUrl + 'uploadfiledata', data);
  }

  // article master add faisal
  mrpTypeView(data: any) {
    return this.http.post(this.baseUrl + 'MrpTypeViewSet', data)
  }
  lotSize(data: any) {
    return this.http.post(this.baseUrl + 'LotSizingProcSet', data)
  }
  priceList(data: any) {
    return this.http.post(this.baseUrl + 'PriceListTypeSet', data)
  }
  configClassType(data: any) {
    return this.http.post(this.baseUrl + 'configClassTypeSet', data)
  }
  listingProcset(data: any) {
    return this.http.post(this.baseUrl + 'ListProcSet', data)
  }
  materialcategory(data: any) {
    return this.http.post(this.baseUrl + 'procurementTypeSet', data)
  }
  mrpControl(data: any) {
    return this.http.post(this.baseUrl + 'MRPControllerSet', data)
  }
  UpdateVendorRequest(data: any) {
    return this.http.post(this.baseUrl + 'UpdateVendorRequest', data)
  }
  // added by sonali sharma (GL)
  fetchVarienceOfMateriall(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceGL', formData);
  }
  chartofaccount(formData: any) {
    return this.http.post(this.baseUrl + 'ChartOfAccount', formData);
  }
  glaccounttype(formData: any) {
    return this.http.post(this.baseUrl + 'GLAccountTypeSet', formData);
  }
  glsortkey(formData: any) {
    return this.http.post(this.baseUrl + 'GLSortKeySet', formData);
  }
  glstatus(formData: any) {
    return this.http.post(this.baseUrl + 'GLFieldStatusGroupSet', formData);
  }
  gltolerance(formData: any) {
    return this.http.post(this.baseUrl + 'GLToleranceGroupSet', formData);
  }
  glreconciliation(formData: any) {
    return this.http.post(this.baseUrl + 'GLReconciliationAccountTypeSet', formData);
  }
  gltaxcategory(formData: any) {
    return this.http.post(this.baseUrl + 'GLTaxCategorySet', formData);
  }
  glaccountNo(formData: any) {
    return this.http.post(this.baseUrl + 'GLAccountCurrencySet', formData);
  }
  glaccountgroup(formData: any) {
    return this.http.post(this.baseUrl + 'GLAccountGroupSet', formData);
  }
  glaccountsubtype(formData: any) {
    return this.http.post(this.baseUrl + 'GLAccountSubType', formData);
  }
  GLCreation(formData: any) {
    return this.http.post(this.baseUrl + 'CreateGLRequest', formData);
  }
  // end added by sonali sharma (GL)

  // cost center api end points add by faisal

  fetchVarienceOfCostCentre(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceCostcenter', formData);
  }
  fetchAllReqcostCenter(formData: any) {

    return this.http.post(this.baseUrl + 'fetchcostcenterRequest', formData)
  }
  MassUploadofCostCentre(formData: any) {

    return this.http.post(this.baseUrl + 'massUploadCostcenter', formData)
  }
  costcontreSentToSap(formData: any) {

    return this.http.post(this.baseUrl + 'SendToSAPcostCenterRequest', formData)
  }
  costcontreMassSentToSap(formData: any) {

    return this.http.post(this.baseUrl + 'SendMasscostcenterRequest', formData)
  }
  createCostCenter(formData: any) {

    return this.http.post(this.baseUrl + 'CreatecostRequest', formData)
  }
  hierarchyArea(data: any) {
    return this.http.post(this.baseUrl + 'hierarchyAreacostlist', data);
  }

  costCenterCat(data: any) {
    return this.http.post(this.baseUrl + 'costcategHelp', data);
  }
  ProfitCenter(data: any) {
    return this.http.post(this.baseUrl + 'profitcenterHelp', data)
  }
  Currency(data: any) {
    return this.http.post(this.baseUrl + 'costcentercurrencyHelp', data);
  }

  FunctinalArea(data: any) {
    return this.http.post(this.baseUrl + 'functionalAreaHelp', data);
  }

  BusninessArea(data: any) {
    return this.http.post(this.baseUrl + 'businessAreaHelp', data);
  }

  costCenter(data: any) {
    return this.http.post(this.baseUrl + 'costCenterlist', data)
  }

  UserResp(data: any) {
    return this.http.post(this.baseUrl + 'userResponsibleHelp', data);
  }

  controlArea(data: any) {
    return this.http.post(this.baseUrl + 'controlAreaHelp', data);
  }
  saveVarienceOfcostCentre(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceCostcenter', formData);
  }
  deleteVarienceOfCostCentre(formData: any) {
    return this.http.post(this.baseUrl + 'deleteVariancecostcenter', formData);
  }
  updateVarienceOfCostCentre(formData: any) {
    return this.http.post(this.baseUrl + 'updateVariancecostCenter', formData);
  }
  //profit center Variance
  fetchVarienceOfProfitCentre(formData: any) {
    return this.http.post(this.baseUrl + 'fetchVarianceProfitcenter', formData);
  }
  saveVarienceOfProfitCentre(formData: any) {
    return this.http.post(this.baseUrl + 'saveVarianceProfitcenter', formData);
  }
  deleteVarienceOfProfitCentre(formData: any) {
    return this.http.post(this.baseUrl + 'deleteVarianceprofitcenter', formData);
  }
  updateVarienceOfProfitCentre(formData: any) {
    return this.http.post(this.baseUrl + 'updateVarianceprofitCenter', formData);
  }
  downloadTemplateProfitCentre() {
    return this.http.get(this.baseUrl + 'profitcenterTemplate');
  }


  //Site SAP Dropdown Validation API

  CompanyCode(formData: any) {
    return this.http.post(this.baseUrl + 'CompanyCodeSet', formData);
  }
  DistributionChannel(formData: any) {
    return this.http.post(this.baseUrl + 'DistributionChannelsSet', formData);
  }
  Division(formData: any) {
    return this.http.post(this.baseUrl + 'DivisionSet', formData);
  }
  SalesDistrict(formData: any) {
    return this.http.post(this.baseUrl + 'SalesDistSet', formData);
  }
  VendorSortKey(formData: any) {
    return this.http.post(this.baseUrl + 'SortKeySetVend', formData);
  }
  listProcSet(formData: any) {
    return this.http.post(this.baseUrl + 'listProcSet', formData);
  }
  calenderIdSet(formData: any) {
    return this.http.post(this.baseUrl + 'calenderIdSet', formData);
  }







}
